<template>
  <div class="state-conventions-wrapper template-3">
    <div class="page-body container mx-lg">
      <header class="page-header">
        <h1>{{ translations.tcStateConventionsEvents }}</h1>
      </header>
      <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
      <div v-if="allowPage">
        <div id="card1" class="card-custom-item">
          <div class="d-flex use-for-tool-tip">
            <h2 class="card-title mr-auto m-0">
              {{ translations.tcConventionsConferencesBlitzes }}
              <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcConventionsConferencesBlitzes">
                <i-tooltip />
              </span>
            </h2>
            <span class="arrow-down-icon" :class="{ arrowup: isToggled }" @click="toggle()">
              <img src="@/assets/svgs/arrow-up.svg" />
            </span>
          </div>
          <div class="list-area" v-if="!isToggled">
            <div
              v-if="iCanSee(secured_controls.manage_state_convention_event_button)"
              class="d-flex custom-btn-section"
            >
              <button @click="handleManageEvents" class="btn btn-primary btn-w-med">
                {{ translations.tcManageEvents }}
              </button>
            </div>
            <div class="row d-flex state-convention-events">
              <div class="col col-4 sc-event-col" v-for="(item, index) in convertedConventions" :key="index">
                <app-event :obj="item" :i18n="translations" @edit_requested="conventionStoreAndRedirect($event)" />
              </div>
            </div>
          </div>
        </div>
        <div id="card2" class="card-custom-item">
          <div class="d-flex">
            <h2 class="card-title mr-auto m-0">{{ translations.tcOtherStateEvents }}</h2>
            <span class="arrow-down-icon" :class="{ arrowup: isToggled1 }" @click="toggle1()">
              <img src="@/assets/svgs/arrow-up.svg" />
            </span>
          </div>
          <div class="list-area" v-if="!isToggled1">
            <div class="d-flex custom-btn-section">
              <button
                v-if="iCanSee(secured_controls.add_state_convention_event_button)"
                @click="handleAddStateEvents"
                class="btn btn-primary btn-w-med mr-3"
              >
                {{ translations.tcAddEvents }}
              </button>
              <button @click="handleViewAllStateEvents" class="btn btn-primary btn-w-med">
                {{ translations.tcViewAllEvents }}
              </button>
            </div>
            <div class="row d-flex state-convention-other-events" v-if="!!translations.common">
              <div class="col col-4 sc-event-col" v-for="(item, index) in convertedStateEvents" :key="index">
                <other-event :obj="item" :i18n="translations" @edit_requested="stateEventStoreAndRedirect($event)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { calendarMixin } from '@/mixins/calendarMixin'
import calendarService from '../../../services/calendarService'
import constantData from '@/json/data.json'
import date_data from '@/json/date.json'
import Event from '@/components/StateConventionsEvent.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import { numericMixin } from '@/mixins/numericMixin'
import otherEvent from '@/components/StateConventionsOtherEvent.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'state-conventions',
  mixins: [calendarMixin, translationMixin, numericMixin],
  data() {
    return {
      translations: {},
      isToggled: false,
      isToggled1: false,
      todaysDate: new Date(),
      otherStateEvents: [],
      secured_controls: {
        add_state_convention_event_button: '30d8ccd4-898b-40b1-a782-317d20369a46',
        manage_state_convention_event_button: '63583225-db66-49f5-9bde-e9342cdd224d',
      },
      eventsSubmitData: {
        state_org_key: '',
        start_date: this.formatDate(this.addDays(new Date(), 0)),
        end_date: this.formatDate(this.addDays(new Date(), 365)),
        rows: 6,
        sort_asc: true,
      },
    }
  },
  async created() {
    await this.pageLoad()
  },
  methods: {
    ...mapActions({
      loadConventions: 'stateConvention/loadConventions',
      loadStateMeetingEvents: 'eventCalendar/loadStateMeetingEvents',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedConventionKey: 'user/setSelectedConventionKey',
      setSelectedMeeting: 'eventCalendar/setSelectedMeeting',
    }),
    toggle() {
      this.isToggled = !this.isToggled
    },
    toggle1() {
      this.isToggled1 = !this.isToggled1
    },
    async pageLoad() {
      this.eventsSubmitData.state_org_key = this.demograph.state_key
      await Promise.all([
        this.setLoadingStatus(true),
        await this.getViewTranslations('date-time'),
        await this.getComponentTranslations('security-banner'),
        await this.getComponentTranslations('common.calendar-event-type-state'),
        await this.loadConventions(),
        await this.loadStateMeetingEvents(this.eventsSubmitData),
      ]).then((results) => {
        this.stripReadableText(results[3])
        const translatedText = {
          ...results[2],
          common: { ...this.translations.common },
        }
        //const componentTranslations = results[2]
        this.$set(this.translations, 'components', translatedText)
        this.setLoadingStatus(false)
      })
    },
    addDays(date, days) {
      var result = new Date(date)
      result.setDate(result.getDate() + days)
      return result
    },
    formatDate(date) {
      let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()
      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [month, day, year].join('-')
    },
    async conventionStoreAndRedirect(data) {
      this.setSelectedConventionKey(data.event_key)
      this.$router.push({ name: 'convention-promo' })
    },
    async stateEventStoreAndRedirect(data) {
      let mt = this.stateMeetings.find((el) => el.mtg_key === data.event_key)
      mt.color =
        mt.vmpt_trn_txt === 'Gideon' ? 'card-Gideon' : mt.vmpt_trn_txt === 'Auxiliary' ? 'card-Auxillary' : 'card-Both'
      let sevt = this.meetingDetails(mt)
      if (!!sevt.mtrkey && sevt.mtrkey !== constantData.empty_guid) {
        let response = await calendarService.getMeetingRecurrence(sevt.mtrkey)
        if (response.status === 200) {
          sevt.reccurrencetype = response.data
          this.setSelectedMeeting(sevt)
          this.$router.push({ name: 'state-event-detail' })
        }
      } else {
        this.setSelectedMeeting(sevt)
        this.$router.push({ name: 'state-event-detail' })
      }
    },
    async handleManageEvents() {
      this.$router.push({ name: 'manage-events' })
    },
    async handleAddStateEvents() {
      this.$router.push({ name: 'select-add-event' })
    },
    async handleViewAllStateEvents() {
      this.$router.push({ name: 'state-event-grid' })
    },
    dayOfTheWeekTranslated(startDate) {
      return this.translations[this.convertValForTranslation(date_data.days[new Date(startDate).getDay()].text)]
    },
  },
  computed: {
    ...mapGetters({
      conventions_active: 'stateConvention/conventions_active',
      demograph: 'user/demograph',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      stateMeetings: 'eventCalendar/stateMeetings',
      userId: 'user/userId',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
    months() {
      return date_data.months.map((dm) => dm.abbrlow)
    },
    convertedConventions() {
      let newAr = this.conventions_active
        .sort((a, b) => (a.event_start_date < b.event_start_date ? -1 : 1))
        .map((c) => {
          return {
            date: {
              month: c.event_start_month,
              day: c.event_start_day,
              year: c.event_start_year,
              time: '',
              fullDate: c.event_start_date,
            },
            title: c.event_name,
            speaker: c.event_location_name,
            event_key: c.event_key,
            editLink: '#',
          }
        })

      return newAr
    },
    convertedStateEvents() {
      let newAr = this.stateMeetings
        .filter((el) => el.mtg_mtt_key !== constantData.meetings.stateconvention.value)
        .sort((a, b) => (a.mtg_start_date > b.mtg_start_date ? 1 : -1))
        .map((c) => {
          let fDate = this.formattedDateForCards(c.mtg_start_date)
          return {
            date: {
              month: fDate.month,
              day: fDate.day,
              year: fDate.year,
              time: c.mtg_start_time,
              fullDate: c.mtg_start_date,
            },
            title: this.translations.common['calendar-event-type-state'][c.mtg_mtt_key] || c.mtt_description_text,
            dayOfWeek: this.dayOfTheWeekTranslated(c.mtg_start_date),
            speaker: c.location,
            event_key: c.mtg_key,
            link: '#',
          }
        })
      return newAr
    },
  },
  components: {
    appEvent: Event,
    iTooltip: iTooltip,
    otherEvent: otherEvent,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/views/ChurchProfile.scss';
@import '@/styles/views/StateConventions.scss';

.i-tooltip {
  vertical-align: super;
  svg path {
    fill: #000 !important;
  }
}
</style>
